import {getData, postData} from "./rest";

const config = {
    photosToLoad: 150,
    searchPageSize: 100,
    albumPageSize: 50,
    apiEndpoint: 'https://photoslibrary.googleapis.com'
};



const BASE_URL = "https://photoslibrary.googleapis.com";
export interface IGetPhotosResponse {
    success:boolean,
    status:string,
    data:any
}


export const getPhotos = async(authToken:string,pageToken: string | null): Promise<IGetPhotosResponse> => {
    const dataRequest: any = {
        pageSize: 50,
        filters: {
            mediaTypeFilter: {
                mediaTypes: ["PHOTO"]
            }
        }
    };
    if (pageToken) {
        dataRequest.pageToken = pageToken;
    }
    const result = await postData(BASE_URL + '/v1/mediaItems:search',dataRequest, {'Authorization': 'Bearer ' + authToken});
    if(result.status === 200) {
        return {
            success:true,
            status:'DONE',
            data: result.data
        }
    }
    return {
        success: false,
        data: null,
        status: result?.data?.error?.status
    }
}


export async function libraryApiSearch(authToken:string, parameters: any,photosToLoad = -1) {
    let photos:any[] = [];
    // let nextPageToken: string | null = null;
    let error = null;

    parameters.pageSize = config.searchPageSize;
    let collectionSize = photosToLoad > 0 ? photosToLoad : config.photosToLoad;
    try {
        // Loop while the number of photos threshold has not been met yet
        // and while there is a nextPageToken to load more items.
        do {
            const searchResponse =
                await fetch(config.apiEndpoint + '/v1/mediaItems:search', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authToken
                    },
                    body: JSON.stringify(parameters)
                });

            const result = await checkStatus(searchResponse);

            // logger.debug(`Response: ${result}`);

            // The list of media items returned may be sparse and contain missing
            // elements. Remove all invalid elements.
            // Also remove all elements that are not images by checking its mime type.
            // Media type filters can't be applied if an album is loaded, so an extra
            // filter step is required here to ensure that only images are returned.
            const items = result && result.mediaItems ?
                result.mediaItems
                    .filter((x: any) => x)  // Filter empty or invalid items.
                    // Only keep media items with an image mime type.
                    .filter((x:any) => x.mimeType && (x.mimeType.startsWith('image/') || x.mimeType.startsWith('video/'))) :
                [];

            photos = photos.concat(items);

            // Set the pageToken for the next request.
            parameters.pageToken = result.nextPageToken;

            // Loop until the required number of photos has been loaded or until there
            // are no more photos, ie. there is no pageToken.
        } while (photos.length < collectionSize &&
        parameters.pageToken != null);

    } catch (err) {
        // Log the error and prepare to return it.
        error = err;
    }
    return {photos, parameters, error};
}


async function checkStatus(response: any){
    if (!response.ok){
        // Throw a StatusError if a non-OK HTTP status was returned.
        let message = "";
        try{
            // Try to parse the response body as JSON, in case the server returned a useful response.
            message = await response.json();
        } catch( err ){
            // Ignore if no JSON payload was retrieved and use the status text instead.
        }
        throw new Error(response.status, response.statusText);
    }

    // If the HTTP status is OK, return the body as JSON.
    return await response.json();
}
