import React, {useState} from "react";
import {Avatar, Button, Paper, Typography} from "@mui/material";
import {Google} from "@mui/icons-material";
import {getAuthLink} from "../services/AuthService";
import LoginTutorial from "../components/LoginTutorial";

export const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const login = () => {
    setLoading(true);
    getAuthLink().then(url => {
      setLoading(false);
      if(url) {
        window.location.href = url;
      }
    });
  }
  return (
      <div
          style={{
            width:'100vw',
            minHeight:'100vh',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            overflow:'auto'
          }}
      >
        <Paper style={{
          minHeight:'290px',
          padding:'20px',
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          flexDirection:'column'
        }}
        elevation={1}>
          <Avatar style={{marginBottom: '10px'}}/>
          <Typography style={{marginBottom: '10px'}}>Inicia sesión con google</Typography>
            <LoginTutorial/>
          {/*<Button variant={'contained'}*/}
          {/*        color={'primary'}*/}
          {/*        startIcon={<Google/>}*/}
          {/*        onClick={login}*/}
          {/*        disabled={loading}*/}
          {/*>*/}
          {/*  Sign in With Google*/}
          {/*</Button>*/}
        </Paper>
      </div>
  );
}
