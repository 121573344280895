// @flow
import React from 'react';
import {PhotoImageData} from "../models/PhotoImageData";

interface PhotoCardProps {
    data: PhotoImageData,
    handleClick:(data: PhotoImageData) => void
}

export const PhotoCard = (props: PhotoCardProps) => {
    const url = props.data.baseUrl + `=w${100}-h100`;
    return (
        <div
            style={{
                width:'100px',
                margin:'4px'
            }}
            onClick={() => props.handleClick(props.data)}
        >
            <img style={{width:"100%", height:"100%",objectFit:'contain'}} src={url}/>
        </div>
        )
}
