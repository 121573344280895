export async function postData(url = '', data = {},headers: any = null) {
    // Default options are marked with *
    let extraHeaders = {}
    if(headers != null) {
        extraHeaders = {...headers}
    }
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/json',
            ...extraHeaders
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    const responseData = await response.json();
    return {status:response.status,data:responseData}; // parses JSON response into native JavaScript objects
}

export interface GetRequest {
    data: any,
    status:number
}
export async function getData(url = '',headers:any = null): Promise<GetRequest> {
    // Default options are marked with *
    let extraHeaders = {}
    if(headers != null) {
        extraHeaders = {...headers}
    }
    const response = await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/json',
            ...extraHeaders
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });
    const data = await response.json();
    return {data,status: response.status}; // parses JSON response into native JavaScript objects
}

export async function fetchBlob(url: string) {
    const response = await fetch(url);

    // Here is the significant part
    // returning a blob instead of json
    return response.blob();
}