import {getData} from "./rest";
import {KeyboardReturnTwoTone} from "@mui/icons-material";
const BASE_URL = "https://us-central1-taller-firebase-463ee.cloudfunctions.net";

export const getAuthLink = async() => {
    const result = await getData(BASE_URL + '/createAuthLink');
    if(!result.data.hasOwnProperty('url')) {
        return null;
    }
    return result.data.url;
}
export const getAuthDriveLink = async() => {
    const result = await getData(BASE_URL + '/createAuthDriveLink');
    if(!result.data.hasOwnProperty('url')) {
        return null;
    }
    return result.data.url;
}

export const getProfile = async(accessToken: string) => {
    return getData("https://www.googleapis.com/oauth2/v3/userinfo?access_token=" + accessToken);
}

export const getAccessToken = async(email:string) => {
    const result = await getData(BASE_URL + '/getValidToken?email=' + email);
    if(result.status === 200 && result.data.hasOwnProperty('accessToken')) {
        return result.data.accessToken;
    }
    return null;
}
