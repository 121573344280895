import React, {PropsWithChildren, useState} from 'react';
import {PhotoImageData} from "../models/PhotoImageData";

type PhotoFilterStateType = {photo: PhotoImageData | null,setPhotoFilter:(photo: PhotoImageData) => void} | undefined;
const PhotoFilterStateContext = React.createContext<PhotoFilterStateType>(undefined)

function PhotoFilterProvider({children}: PropsWithChildren) {
    const [photo, setPhoto] = useState<PhotoImageData | null>(null);
    const setPhotoFilter = (photo: PhotoImageData) => {
        setPhoto(photo);
    }
    const value = {photo,setPhotoFilter}
    return (
        <PhotoFilterStateContext.Provider value={value}>
            {children}
        </PhotoFilterStateContext.Provider>
    )
}

function usePhotoFilter() {
    const context = React.useContext(PhotoFilterStateContext)
    if (context === undefined) {
        throw new Error('usePhotoFilter must be used within a PhotoFilterProvider')
    }
    return context
}

export {PhotoFilterProvider, usePhotoFilter}
