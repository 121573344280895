import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Link, Paper, StepButton, StepContent} from "@mui/material";
import FirstScreen from "../firstScreen.png";
import SecondScreen from "../secondScreen.png";
import ThirdScreen from "../thirdScreen.png";
import {Google} from "@mui/icons-material";
import {getAuthLink} from "../services/AuthService";
import './LoginTutorial.css'
const steps = ['Privacidad', 'Validación', 'permisos', 'login'];

export default function LoginTutorial() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const [loading, setLoading] = useState(false);
    const isStepOptional = (step: number) => {
        return step === 1;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    const login = () => {
        setLoading(true);
        getAuthLink().then(url => {
            setLoading(false);
            if(url) {
                window.location.href = url;
            }
        });
    }
    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };
    return (
        <Box sx={{width: '100%'}}>
            <Stepper nonLinear activeStep={activeStep} orientation={'vertical'}>
                <Step>
                    <StepButton onClick={handleStep(0)}>Aviso privacidad</StepButton>
                    <StepContent>
                        <Privacy/>
                    </StepContent>
                </Step>
                <Step>
                    <StepButton onClick={handleStep(1)}>Validación app</StepButton>
                    <StepContent>
                        <SecurityScreen/>
                    </StepContent>
                </Step>
                <Step>
                    <StepButton onClick={handleStep(2)}>Permisos</StepButton>
                    <StepContent>
                        <Permissions/>
                    </StepContent>
                </Step>
                <Step>
                    <StepButton onClick={handleStep(3)}>Iniciar sesión</StepButton>
                    <StepContent>
                        <Button variant={'contained'}
                                color={'primary'}
                                startIcon={<Google/>}
                                onClick={login}
                                disabled={loading}
                        >
                            Sign in With Google
                        </Button>
                    </StepContent>
                </Step>
            </Stepper>
            <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{mr: 1}}
                >
                    Atrás
                </Button>
                <Box sx={{flex: '1 1 auto'}}/>
                <Button onClick={handleNext} disabled={activeStep === steps.length - 1}>
                    Siguiente
                </Button>
            </Box>
        </Box>
    );
}


const Privacy = () => {
    return (
        <div>
            <Typography>Medidas importantes para tu privacidad como usuario:</Typography>
            <ul>
                <li><Typography variant={'body2'}>Todas las fotos son recibidas en el dispositivo en el que se accesa y
                    no pasan en ningún momento por nuestros servidores</Typography></li>
                <li><Typography variant={'body2'}>No guardamos ninguna información personal, más que el email para
                    recordar tu acceso</Typography></li>
                <li><Typography variant={'body2'}>La autenticación es gestionada directamente por google y tiene una
                    validez de una hora, después de la cuál se tendrá que volver a pedir acceso</Typography></li>
                <li><Typography variant={'body2'}>Una vez se accesa a la app, estas fotos tienen un filtro de privacidad
                    para no comprometer su contenido (el usuario puede deshabilitar esta función) </Typography></li>
            </ul>
            <Typography>Cualquier duda aclaración o comentario, comunicarse con el correo de contacto <Link
                href={"mailto:fnixro2@gmail.com"}>fnixro2@gmail.com</Link></Typography>
        </div>
    )
}


const SecurityScreen = () => {
    return (
        <div>
            <Typography>La aplicación se encuentra en fase de prueba por lo que la verificación de Google no está
                presente, es posible que se presente una pantalla de advertencia, sigue los pasos abajo:</Typography>
            <br/>
            <Typography variant={'body2'}>Da click en configuración avanzada</Typography>
            <Paper
                style={{
                    padding: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: 'min-content'
                }}
                elevation={3}
            >
                <img className={'photo-tutorial'} src={FirstScreen}/>
            </Paper>
            <Typography variant={'body2'}>Da click en el último link (felixehuan.dev)</Typography>
            <Paper
                style={{
                    padding: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: 'min-content'
                }}
                elevation={3}
            >
                <img className={'photo-tutorial'} src={SecondScreen}/>
            </Paper>
        </div>
    )
}

export const Permissions = () => {
    return (
        <div>
            <Typography variant={'body2'}>Para poder elegir las fotos a las que te gustaría agregar filtros
                debes aceptar el permiso de lectura de tu biblioteca de fotos (<b>ninguna foto es almacenada es nuestro
                    servidor ya que todo el proceso se realiza en el dispositivo</b>) </Typography>
            <Paper
                style={{
                    padding: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: 'min-content'
                }}
                elevation={3}
            >
                <img className={'photo-tutorial'} src={ThirdScreen}/>
            </Paper>
            <Typography>Una vez terminado el proceso serás redireccionado a la aplicación</Typography>
        </div>
    )
}
