// @flow
import React, {useEffect, useRef, useState} from 'react';
import {usePhotoFilter} from "../providers/PhotoProvider";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useNavigate} from "react-router-dom";
export const FilteredPhoto = () => {
    const [url, setUrl] = useState<string | null>(null);
    const [thumbnail, setThumbnail] = useState<string | null>(null);
    const [className, setClassName] = useState<string | undefined>(undefined);
    const {photo} = usePhotoFilter();
    const navigate = useNavigate();
    useEffect(() => {
        if (!!photo) {
            setUrl(photo.baseUrl + `=w${500}-h${500};`);
            setThumbnail(photo.baseUrl + `=h${100};`);
        }
    }, [photo]);

    const handleClick = (filterName:string) => {
        setClassName(filterName);
    }

    const navigateBack = () => {
         navigate('/');
    }
    return (
        <div
            style={{
                width: '100%',
                height: 'calc(100vh - 56px)',
                paddingTop:'20px'
            }}
        >
            <div style={{ display: "flex", alignItems:'center', marginLeft:'10px'}} onClick={navigateBack} >
                <ArrowBackIosIcon/>
                Back
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "calc(100% - 200px)",
                    padding: "20px",
                }}
            >
                {
                    url && <img
                    src={url}
                    className={className}
                    style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                }}/>
                }
            </div>
            <div
                style={{
                    overflowX: "auto",
                    overflowY: "hidden",
                    whiteSpace: "nowrap"
                }}
            >
                {thumbnail ? filters.map(el => <FilterThumbnail key={el} handleClick={handleClick} thumbnail={thumbnail} filterName={el} id={photo?.id}/>) : null}
            </div>
        </div>
    )
};


const FilterThumbnail = (props: any) => {
    // First parameter is the image object, and the second is the filter you wish to apply.
    return (
        <span
            style={{
                margin: '8px',
                display: "inline-flex",
                flexDirection: "column",
            }}
            onClick={() => props.handleClick(props.filterName)}
        >
            <img className={props.filterName}  id={`${props.id}-${props.filterName}`} src={props.thumbnail} style={{width: 'auto', height: '100px', objectFit:'contain'}}            />
            <p style={{margin:0}}><i>{props.filterName}</i></p>
        </span>
    )
}


const filters = [
    "_1977",
    "aden",
    "brannan",
    "brooklyn",
    "clarendon",
    "earlybird",
    "gingham",
    "hudson",
    "inkwell",
    "kelvin",
    "lark",
    "lofi",
    "maven",
    "mayfair",
    "moon",
    "nashville",
    "perpetua",
    "reyes",
    "rise",
    "slumber",
    "stinson",
    "toaster",
    "valencia",
    "walden",
    "willow",
    "xpro2",
];