import React, {useEffect, useState} from 'react';
import './App.css';
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {AppRoutes} from "./App.routes";
import {PhotoFilterProvider} from "./providers/PhotoProvider";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function App() {
    return (
        <ThemeProvider theme={darkTheme}>
                <CssBaseline/>
                <PhotoFilterProvider>
                    <AppRoutes/>
                </PhotoFilterProvider>
        </ThemeProvider>

    );
}

export default App;
