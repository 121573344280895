import React, {useEffect, useState} from "react";
import {BrowserRouter, Route, Routes, Outlet, Navigate, useSearchParams} from 'react-router-dom';
import {LoginPage} from "./pages/LoginPage";
import {PhotosPage} from "./pages/PhotosPage";
import {getAccessToken, getProfile} from "./services/AuthService";
import {CircularProgress} from "@mui/material";
import {FilteredPhoto} from "./pages/FilteredPhoto";
import MenuAppBar from "./components/MenuAppBar";

export interface UserProfile {
    email:string
    name:string
    picture: string
    [key:string]: any
}
export const AppRoutes = () => {
    const [profile, setProfile] = useState<UserProfile | null>(null);
    const [status, setStatus] = useState('initial');
    const [token, setToken] = useState<string | null>(null);
    useEffect(() => {
        const accessToken = parseAccessTokenQuery();
        const storedProfile = getProfileStored();
        setStatus('in_progress');
        if(accessToken != null) {
            getProfile(accessToken).then(result => {
                if(result.status === 200) {
                    localStorage.setItem('profile',JSON.stringify(result.data));
                    setProfile(result.data);
                    setToken(accessToken);
                } else {
                    localStorage.removeItem('profile');
                    console.error(result);
                }

            }).finally(() => {
                setStatus('finished');
                window.location.href = window.location.origin
            });
        } else {
            if(storedProfile != null) {
                getAccessToken(storedProfile.email).then(accessTok => {
                    if(accessTok) {
                        setToken(accessTok);
                        setProfile(storedProfile);
                        setStatus('finished');
                    }
                }).finally(() => setStatus('finished'));
            } else {
                //not logged in at this moment
                setStatus('finished');
            }
        }

    },[]);
    if(status === 'in_progress' || status === 'initial') {
        return <CircularProgress/>
    }
        return (
            <BrowserRouter>
                    <Routes>
                        <Route path={''} element={<ProtectedRoute user={profile} redirectPath={'/login'}/>}>
                            <Route path="" element={<PhotosPage profile={profile!} token={token!}/>}/>
                            <Route path="filter" element={<FilteredPhoto/>}/>
                        </Route>
                        <Route path="/login" element={<LoginPage/>}/>
                    </Routes>
            </BrowserRouter>
        )
}

interface IProtectedRoute {
    user:any,
    redirectPath:string
}
const ProtectedRoute = ({ user, redirectPath = '/login' }: IProtectedRoute) => {
    if (!user) {
        return <Navigate to={redirectPath} replace />;
    }
    return (
        <>
            <MenuAppBar profile={user}/>
            <Outlet />
        </>
    )
};

const parseAccessTokenQuery = () => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const accessToken = params.get('accessToken');
    return accessToken;
}

const getAccessTokenStored = () => {
    const accessToken = localStorage.getItem('accessToken');
    return accessToken
}
const getProfileStored = () => {
    const profile = localStorage.getItem('profile');
    if(profile) {
        return JSON.parse(profile) as UserProfile;
    }
    return null;
}
